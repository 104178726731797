define("discourse/plugins/discourse-calendar/discourse/templates/components/add-event-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="add-event-button">
    {{d-button
      action=(action "showAddEventModal")
      class="btn btn-default"
      icon="calendar-day"
      label="discourse_calendar.discourse_post_event.builder_modal.attach"
    }}
  </div>
  
  */
  {
    "id": "rp/GQEJu",
    "block": "[[[10,0],[14,0,\"add-event-button\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"action\",\"class\",\"icon\",\"label\"],[[28,[37,2],[[30,0],\"showAddEventModal\"],null],\"btn btn-default\",\"calendar-day\",\"discourse_calendar.discourse_post_event.builder_modal.attach\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"div\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/templates/components/add-event-button.hbs",
    "isStrictMode": false
  });
});