define("discourse/plugins/discourse-calendar/discourse/components/modal/post-event-invite-user-or-group", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n
      "discourse_calendar.discourse_post_event.invite_user_or_group.title"
    }}
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
  >
    <:body>
      <form>
        <EventField>
          <EmailGroupUserChooser
            @value={{this.invitedNames}}
            @options={{hash
              fullWidthWrap=true
              includeMessageableGroups=true
              filterPlaceholder="composer.users_placeholder"
              excludeCurrentUser=true
            }}
          />
        </EventField>
      </form>
    </:body>
    <:footer>
      <DButton
        @type="button"
        class="btn-primary"
        @label="discourse_calendar.discourse_post_event.invite_user_or_group.invite"
        @action={{this.invite}}
      />
    </:footer>
  </DModal>
  */
  {
    "id": "F5nZTbbV",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\",\"@flash\"],[[28,[37,1],[\"discourse_calendar.discourse_post_event.invite_user_or_group.title\"],null],[30,1],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,\"form\"],[12],[1,\"\\n      \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@value\",\"@options\"],[[30,0,[\"invitedNames\"]],[28,[37,6],null,[[\"fullWidthWrap\",\"includeMessageableGroups\",\"filterPlaceholder\",\"excludeCurrentUser\"],[true,true,\"composer.users_placeholder\",true]]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,8],[[24,0,\"btn-primary\"]],[[\"@type\",\"@label\",\"@action\"],[\"button\",\"discourse_calendar.discourse_post_event.invite_user_or_group.invite\",[30,0,[\"invite\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\":body\",\"form\",\"event-field\",\"email-group-user-chooser\",\"hash\",\":footer\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/components/modal/post-event-invite-user-or-group.hbs",
    "isStrictMode": false
  });
  class PostEventInviteUserOrGroup extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "invitedNames", [_tracking.tracked], function () {
      return [];
    }))();
    #invitedNames = (() => (dt7948.i(this, "invitedNames"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "flash", [_tracking.tracked], function () {
      return null;
    }))();
    #flash = (() => (dt7948.i(this, "flash"), void 0))();
    async invite() {
      try {
        await (0, _ajax.ajax)(`/discourse-post-event/events/${this.args.model.event.id}/invite.json`, {
          data: {
            invites: this.invitedNames
          },
          type: "POST"
        });
        this.args.closeModal();
      } catch (e) {
        this.flash = (0, _ajaxError.extractError)(e);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "invite", [_object.action]))();
  }
  _exports.default = PostEventInviteUserOrGroup;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostEventInviteUserOrGroup);
});