define("discourse/plugins/discourse-calendar/discourse/templates/connectors/user-custom-preferences/region", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">{{i18n
        "discourse_calendar.region.title"
      }}</label>
    <div class="controls">
      <RegionInput
        @value={{model.custom_fields.holidays-region}}
        @allowNoneRegion={{true}}
        @onChange={{action "onChange"}}
      />
    </div>
    <DButton
      @icon="globe"
      @label="discourse_calendar.region.use_current_region"
      @action={{action "useCurrentRegion"}}
    />
  </div>
  */
  {
    "id": "mnPXXchZ",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,2],[\"discourse_calendar.region.title\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@value\",\"@allowNoneRegion\",\"@onChange\"],[[30,0,[\"model\",\"custom_fields\",\"holidays-region\"]],true,[28,[37,4],[[30,0],\"onChange\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,5],null,[[\"@icon\",\"@label\",\"@action\"],[\"globe\",\"discourse_calendar.region.use_current_region\",[28,[37,4],[[30,0],\"useCurrentRegion\"],null]]],null],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-calendar/discourse/templates/connectors/user-custom-preferences/region.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"div\",\"label\",\"i18n\",\"region-input\",\"action\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/templates/connectors/user-custom-preferences/region.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});