define("discourse/plugins/discourse-calendar/discourse/templates/connectors/after-user-name/holiday-flair", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <img src={{holidayEmoji}} class="emoji" alt={{holidayEmojiName}} />
  */
  {
    "id": "KN18xFoc",
    "block": "[[[44,[[28,[37,1],null,[[\"holidayEmoji\",\"holidayEmojiName\"],[[28,[32,0],[\"holidayEmoji\"],null],[28,[32,0],[\"holidayEmojiName\"],null]]]]],[[[10,\"img\"],[15,\"src\",[52,[30,1,[\"holidayEmoji\"]],[28,[30,1,[\"holidayEmoji\"]],null,null],[28,[32,1],[[30,0],\"holidayEmoji\",\"[\\\"The `holidayEmoji` property path was used in the `discourse/plugins/discourse-calendar/discourse/templates/connectors/after-user-name/holiday-flair.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.holidayEmoji}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]],[14,0,\"emoji\"],[15,\"alt\",[52,[30,1,[\"holidayEmojiName\"]],[28,[30,1,[\"holidayEmojiName\"]],null,null],[28,[32,1],[[30,0],\"holidayEmojiName\",\"[\\\"The `holidayEmojiName` property path was used in the `discourse/plugins/discourse-calendar/discourse/templates/connectors/after-user-name/holiday-flair.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.holidayEmojiName}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]],[12],[13]],[1]]]],[\"maybeHelpers\"],false,[\"let\",\"hash\",\"img\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/templates/connectors/after-user-name/holiday-flair.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});